<template>
  <Page :title="'仲裁服务'">
    <article id="page-assistance" class="business_banner">
      <section class="container container-main">
        <div class="business-banner-head">
          <h1 class="title">仲裁服务</h1>
          <p class="desc">
            仲裁是指由双方当事人协议将争议提交（具有公认地位的）第三者，由该第三者对争议的是非曲直进行评判并作出裁决的一种解决争议的方法。仲裁异于诉讼和审判，仲裁需要双方自愿，也异于强制调解，是一种特殊调解，是自愿型公断，区别于诉讼等强制型公断。
          </p>
          <div class="business-apply-button mt-5">
            <a-button class="mr-3"><router-link to="/arbitrationapply" target="_blank">预约办理</router-link></a-button>
          </div>
          <span class="business-banner-head-icon flyz-icon"></span>
        </div>
        <div class="bg_white w-1200 br-4 p-3">
        <div class="subtitle">
          <h2>仲裁申请指南</h2>
          <hr class="underline" />
        </div>
        <div class="comm-html">
        <a-tabs
          default-active-key="condition"
          :animated="false"
          class="block-tabs mt-5"
        >
        <a-tab-pane key="condition" tab="仲裁指引">
            <div class="ov_text">
              <div class="law_methods_main_text">
                <p>一、什么是仲裁？</p>
                <p>仲裁是建立在当事人意思自治基础上解决争议的法定方式之一。从法律意义上讲，仲裁是指发生纠纷的双方当事人，根据他们在纠纷发生前或纠纷发生后达成的仲裁协议，自愿将争议提交确定的仲裁机构，由该仲裁机构居于中立地位作出独立判断和裁决，及时解决纠纷，维护当事人合法权益的具有法律效力的争议解决制度。</p>
                <p>二、发生经济纠纷后，只能到法院打官司吗？</p>
                <p>解决经济纠纷的合法途径有和解、调解、仲裁、诉讼等。当事人可以进行谈判磋商，将纠纷交由有权的第三方主持调解，也可以申请仲裁或起诉到法院，由仲裁员或法官居中审理和裁判。</p>
                <p>三、仲裁可以受理哪些纠纷？不可以受理哪些纠纷？</p>
                <p>（一）《中华人民共和国仲裁法》第二条规定，平等主体的公民、法人和其他组织之间发生的合同纠纷和其他财产权益纠纷，可以仲裁。具体包括下列纠纷：</p>
                <p>1.合同法规定的有关合同纠纷。主要包括买卖合同，建设工程合同，借款合同，技术合同，运输合同，租赁合同，融资租赁合同，承揽合同，保管合同，仓储合同，委托合同，行纪合同，居间合同，赠与合同，供用电、水、气、热合同等引起的纠纷。</p>
                <p>2.其他民商事法律中规定的合同纠纷。主要包括著作权合同纠纷，委托创作、出版、专利使用许可、商标使用许可等合同纠纷；以及一些涉外民商事合同纠纷，主要为中外合资合同、中外合作合同纠纷和涉外经济贸易中的其他合同纠纷。</p>
                <p>3.其他财产权益纠纷。主要指因侵权产生的纠纷，包括海事侵权纠纷、侵犯消费者权益纠纷、产品质量侵权纠纷等涉及财产权益的侵权纠纷。</p>
                <p>（二）下列纠纷不能申请仲裁：</p>
                <p>1.婚姻、收养、监护、抚养、继承纠纷；</p>
                <p>2.依法应当由行政机关处理的行政争议；</p>
                <p>3.劳动争议；</p>
                <p>4.农村集体经济组织内部的农业承包合同纠纷。</p>
                <p>四、仲裁有哪些特点和优势?</p>
                <p>（一）意思自治。仲裁以当事人自愿为前提，赋予当事人较多的选择权，当事人有权选择任何地方的仲裁机构，有权决定提交仲裁解决的争议事项范围，有权选定自己信任的仲裁员等。</p>
                <p>（二）专家裁案。仲裁员是由符合法定条件的具有较高</p>
                <p>专业知识、良好法律素养和高尚执业道德，并在专业领域有一定造诣的专家和知名人士担任，有利于提高案件质量，有利于争议公平合理解决。</p>
                <p>（三）程序灵活。在不违背基本程序要求的前提下，允许依当事人约定而简化程序，节约成本，灵活快捷解决纠纷。&nbsp;</p>
                <p>（四）一裁终局。仲裁裁决一经作出即具有与法院终审判决相同的法律效力，对双方当事人具有约束力并可强制执行。</p>
                <p>（五）保守秘密。除非双方当事人协议公开，仲裁实行不公开审理，有利于保护当事人秘密，也有利于维护当事人的商业信誉。</p>
                <p>（六）国际认可。仲裁是国际公认并广泛采用的解决争议的重要方式之一，我国已加入1958年在纽约订立的《承认与执行外国仲裁裁决公约》（亦称"1958年纽约公约"）。根据该公约，中国的各仲裁委员会作出的涉外仲裁裁决，目前可以在世界上160多个国家得到承认和执行。</p>
                <p>五、仲裁实行级别管辖和地域管辖吗？</p>
                <p>《中华人民共和国仲裁法》第六条规定，仲裁委员会应当由当事人协议选定，仲裁不实行级别管辖和地域管辖。各个仲裁机构之间不存在隶属关系，仲裁机构受理案件也不受争议标的额大小及案件复杂程度和案件性质的限制。当事人在选择、约定仲裁机构时，不因当事人所在地、纠纷发生地而受到地域的限制。</p>
                <p>六、申请仲裁应当符合哪些条件？</p>
                <p>（一）有仲裁协议。签订合同时或发生合同争议后，双方约定仲裁条款或达成仲裁协议，这是申请仲裁必备的首要条件，没有仲裁协议就不能申请仲裁。</p>
                <p>范例1（合同中直接约定适用于签订合同时)：第XX条 纠纷解决方式：因本合同引起的或与本合同有关的任何争议，均提交XX仲裁委员会，按照该会的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。确认仲裁文书送达地址：甲方：XX，乙方：XX。</p>
                <p>范例2(合同外单独约定适用于发生合同争议时)：甲、乙双方因《XX合同》发生争议，经协商均同意提请XX仲裁委员会，按照该会的仲裁规则进行仲裁。仲裁裁决是终局的，对双方均有约束力。确认仲裁文书送达地址：甲方：XX，乙方：XX。</p>
                <p>（二）有具体的仲裁请求和事实、理由。具体的仲裁请求是指仲裁申请人想通过仲裁解决什么问题，保护自己的什么财产权益。事实是指合同纠纷和其他财产权益纠纷发生的经过及申请仲裁的理由，并提交证明事实和理由的证据。</p>
                <p>（三）属于仲裁委员会的受理范围。即纠纷应当是平等主体的公民、法人和其他组织之间发生的合同纠纷和其他财产权益纠纷。不属于仲裁委员会的受案范围的，不能申请仲裁。</p>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="material" tab="仲裁流程">
            <div class="ov_text"><img src="../../assets/images/zc_flow.png"></div>
          </a-tab-pane>
          <a-tab-pane key="range" tab="规范性文件">
            <div class="ov_text">
              <p>1、司法部关于印发《关于规范和加强仲裁机构登记管理的意见》的通知</p>
              <p>2、国务院办公厅关于印发 《重新组建仲裁机构方案》、《仲裁委员会 登记暂行办法》、《仲裁委员会仲裁收费办法》的通知</p>
              <p>3、中华人民共和国仲裁法</p>
            </div>
          </a-tab-pane>
          <a-tab-pane key="form" tab="收费执行标准">
            <div class="ov_text">
              <p>仲裁收费包括案件受理费和案件处理费。按照《仲裁委员会仲裁收费办法》（国办发〔1995〕44号）和省级物价、财政部门规定执行。</p>
              <p>（一）案件受理费收取标准为：</p>
              <p>1000元以下的部分 40-100元；</p>
              <p>1001元至50000元的部分 按4-5%交纳；</p>
              <p>50001元至100000元的部分 按3-4%交纳；</p>
              <p>100001元至200000元的部分 按2-3%交纳；</p>
              <p>200001元至500000元的部分 按1-2%交纳；</p>
              <p>500001元至1000000元的部分 按0.5-1%交纳；</p>
              <p>1000001元以上的部分 按0.25-0.5%交纳。</p>
              <p>（二）案件处理费的收费标准为：按照国家有关规定执行；国家没有规定的，按照合理的实际支出收取。</p>
            </div>
          </a-tab-pane>
        </a-tabs>
        </div>
        <IntelligentConsult :value="arbitrationapplyUrl"></IntelligentConsult>
        <div class="mt-5 mechanism_list">
          <a-tabs
            default-active-key="org"
            :animated="false"
            class="tabs custom-tabs"
          >
            <a-tab-pane key="org" tab="仲裁机构">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.provider_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Collection
                name="provider"
                :page="provider.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  provider_name: providerQuery.provider_name,
                  provider_type: '仲裁服务机构'
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.provider_id"
                  >
                    <Org
                      :post="item"
                    >
                    </Org>
                  </a-col>
                </a-row>

                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
            <a-tab-pane key="personnel" tab="仲裁人员">
              <div class="screen_box">
                <City @change="handleCityChange"></City>
                <div class="tabs_search">
                  <p class="keyword">关键字查询</p>
                  <div>
                    <div>
                      <a-input-search
                        style="width: 700px"
                        placeholder="请输入关键字搜索"
                        enter-button="搜索"
                        size="large"
                        @search="
                          (value) => (providerQuery.person_name = value)
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Collection
                name="person"
                :page="person.current"
                :query="{
                  city: providerQuery.city,
                  area: providerQuery.area,
                  person_name: providerQuery.person_name,
                  person_type: ''
                }"
                v-slot="{ collection, count }"
              >
                <a-row :gutter="[16, 16]" class="mt-5">
                  <a-col
                    :span="6"
                    v-for="item in collection"
                    :key="item.person_id"
                  >
                    <Person
                      :post="item"
                    >
                    </Person>
                  </a-col>
                </a-row>
                <a-pagination
                  class="mb-5"
                  show-quick-jumper
                  :current="person.current"
                  :defaultPageSize="person.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
              </Collection>
            </a-tab-pane>
          </a-tabs>
          <div class="tab-content">
          </div>
        </div>
        </div>
      </section>
    </article>
  </Page>
</template>

<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
import Org from '@/components/business/Org'
import City from '@/components/business/City'
import Person from '@/components/business/Person'
import IntelligentConsult from '@/components/business/IntelligentConsult'
export default {
  components: {
    Collection,
    Page,
    Org,
    City,
    Person,
    IntelligentConsult
  },
  data () {
    return {
      arbitrationapplyUrl: '/arbitrationapply',
      provider: {
        current: 1,
        defaultPageSize: 12
      },
      person: {
        current: 1,
        defaultPageSize: 12
      },
      providerQuery: {
        city: '',
        area: '',
        provider_name: '',
        person_name: ''
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = page
    },
    handleCityChange (obj) {
      this.providerQuery.city = obj.city
      this.providerQuery.area = obj.area
    }
  }
}
</script>
